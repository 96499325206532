@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600&family=Lato:wght@100;300;400;900&family=Passion+One&family=Poppins:wght@500;600;700&family=Roboto:wght@100;400;500&display=swap');


body {
  font-family: 'Lato', 'Kanit', sans-serif;
  font-weight: 100;
}

h1 {
  font-size: 2rem;
  margin: 10px 0 15px;
  font-weight: 100;
}

a {
  text-decoration: none;
  color: white;
}

h1 {
  text-align: left;
}