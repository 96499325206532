.sidebar-container {
  text-align: left;
  line-height: 220%;
  padding-right: 30px;

  h1 {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 768px) {
    line-height: 120%;
  }
}

.menu-item {
  margin: 0 0 5px;
  list-style-type: none;
  cursor: pointer;
}