.App {
  background: url(../../assets/06-16mm-Film-Grain-Gallery.jpg);
  text-align: center;
  min-height: 100vh;
  color: white;
  padding: 40px;
  font-size: 1.4rem;
  max-width: 1400px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.description {
  text-align: left;
  line-height: 140%;
  white-space: pre-wrap;
  margin: 20px 0;
  font-size: 20px;
}

.yt-container {
  iframe {
    max-width: 100% !important;
  }
}