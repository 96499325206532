.page-content {
  white-space: pre-wrap;
  text-align: left;

  a {
    font-weight: 400;
  }
  a:hover {
    text-decoration: underline;
  }
}

.MuiImageListItem-root {
  cursor: pointer;
  filter: grayscale(100%);
  transition: filter 0.5s ease-in-out ;

  .MuiImageListItemBar-root {
    display: none;
  }

  &:hover {
    filter: none;

    .MuiImageListItemBar-root {
      display: block;
    }
  }
}

// grid of 3 columns and equal rows
.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 0rem;
  margin: 20px 0;

  // on mobile, make one column only
  @media (max-width: 1024) {
    grid-template-columns: repeat(2, 1fr);
  }

  // on mobile, make one column only
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.MuiImageListItem-root .MuiImageListItemBar-root {
  @media (max-width: 768px) {
    display: block !important;
  }
}